import styled from 'styled-components';
import { device } from '../../style/breakpoints';

export const Wrapper = styled.div`
  span.desc{
        display: flex;
        justify-content: center;
        color: black !important;
    }
      .custom-select{
            max-width: 536px;
            margin-bottom: 65px;
            .ant-form-item .ant-form-item-control .ant-select-selector{
                background-color: #fff;
            }
            &.adddeals-select{
                margin-bottom: 30px;
            }
        }
        .btn-group {
            margin-top: 50px;
            button{
                margin-right: 30px;
                &:last-child{
                    margin-right: 0;
                }
            }
            .ant-btn.ant-btn-primary{
                padding: 16px 61px;
            }
        }
        ul.ant-picker-ranges{
            display:flex !important;
            align-items:center !important;
            padding: 10px !important;
        }
       .ant-btn-primary.ant-btn-sm{
            height:10px !important;
        }

        @media only screen and (${device.sm}){
        
            .custom-select{
                margin-bottom:30px;
            }
            .btn-group {
                margin-top: 20px;
                .ant-btn.ant-btn-primary{
                    padding: 11px 35px;
                }
            }
        
        }  
`;