import React, { StrictMode } from "react";
// import "./index.scss";
import App from "./container/App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { register, registerServiceWorker } from "./serviceWorkerRegistration";

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<StrictMode><App /></StrictMode>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
register();
registerServiceWorker();
reportWebVitals();
