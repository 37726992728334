
import styled from 'styled-components';
import { device } from '../../style/breakpoints';
import { toAbsoluteUrl } from '../../utils';


export const Wrapper = styled.div`
        background-color:var(--theme--color);
        background-image: url(${toAbsoluteUrl(`/images/login-bg.png`)});
        background-position: center;
        background-repeat: no-repeat;
        background-attachment:fixed;
        background-size: cover;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px;
        .login-wrap{
            max-width:600px;
            width:100%;
            .login-form{
                background-color:var(--white--color);
                border-radius: 40px;
                padding:32px;
                box-shadow:0px 15px 70px -8px rgba(0, 0, 0, 0.25);
                -ms-box-shadow:0px 15px 70px -8px rgba(0, 0, 0, 0.25);
                -moz-box-shadow:0px 15px 70px -8px rgba(0, 0, 0, 0.25);
                -webkit-box-shadow:0px 15px 70px -8px rgba(0, 0, 0, 0.25);
            }
            .login-logo{
                margin-bottom:46px;
                max-width:100%;
            }
            .login-logo img{
                max-width:100%;
            }
            .title{
                font-size: 26px;
                color: #242424;
                margin:24px 0 16px;
                font-weight: 500;
            }
            .desc{
                font-family: 'Metropolis Semi';
                font-weight: 400;
                font-size: 14px;
                color: #6B7280;
                margin-bottom:24px;
            }
            .title-f{
                color: var(--theme--color);
                font-family: Metropolis Semi;
                font-size: 34px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%; /* 51px */
            }
            .desc-f{
                color: var(--theme--color);
                text-align: center;
                font-family: Metropolis Semi;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                margin-bottom:41px;
                padding: 0px 16px;
                line-height: 150%; /* 30px */
            }
            .ant-form-item .ant-form-item-control .ant-input{
                border-radius:15px;
                gap:20px;
                padding: 6px 20px;
            }
            .ant-btn.ant-btn-primary{
                border-radius: 15px;
                color:  var(--white--color);
                font-size: 24px;
                font-style: normal;
                font-weight: 700;
                font-family: 'Metropolis Semi';
                // line-height: 26px; 
            }
            .f-btn{
                color: #FE7A18;
                font-size: 22px;
                font-style: normal;
                font-family: 'Metropolis Semi';
                font-weight: 600;
                text-align:center;       
                display:flex;
                justify-content:center;      
                margin-top:46px;   
                // text-decoration:underline;
            }
            .cancel-btn{
                color: #FE7A18;
                font-family: 'Metropolis Semi';
                font-size: 22px;
                font-style: normal;
                font-weight: 600;
                line-height: 150%;
                text-align:center;       
                display:flex;
                justify-content:center; 
            }
            // .horizontal-line{
            //     border-top: 1px solid rgb(230, 232, 240);
            //     padding-top: 24px;
            //     margin-top: 24px;
            // }
            .location{
                margin-top:32px;
                .location-btn{
                    font-family: 'Metropolis Semi';
                    font-weight: 700;
                    font-size: 22px;
                    color:var(--theme--color);
                    background-color:var(--white--color);
                    border-radius: 28px;
                    padding:29px 10px;
                    box-shadow:0px 15px 70px -8px rgba(0, 0, 0, 0.25);
                    -ms-box-shadow:0px 15px 70px -8px rgba(0, 0, 0, 0.25);
                    -moz-box-shadow:0px 15px 70px -8px rgba(0, 0, 0, 0.25);
                    -webkit-box-shadow:0px 15px 70px -8px rgba(0, 0, 0, 0.25);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg{
                        margin-left:65px;
                    }
                    Button{
                        font-family: 'Metropolis Semi';
                        font-size: 22px;
                        font-weight: 700;
                        margin-left:36px;
                        padding:8px 21px;
                    }
                }
            }
        }
        @media only screen and (${device.lg}){
            .login-wrap{
                .location{
                    .location-btn{
                        svg{
                            margin-left:35px;
                        }
                    }
                }
            }
        }
        @media only screen and (${device.sm}){
            .login-wrap{
                .login-form{
                    padding:20px;
                }
                .location{
                    margin-top:32px;
                    .location-btn{
                        font-size: 20px;
                        padding: 18px 10px;
                        svg{
                            margin-left: 12px;
                        }
                    }
                }
            }
        }
        @media only screen and (${device.xs}){
            .login-wrap{
                .location{
                    .location-btn{
                        flex-direction:column;
                        gap:10px;
                    Button{
                        margin: 0;
                    }
                    }
                }
            }
        }

`;