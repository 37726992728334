import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";


const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const otpVerifyStatus = useSelector((state) => state.auth.otpVerifyStatus);
  const adsStatusDetails = useSelector((state) => state?.venue?.adsStatusDetails);


  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/login',
        { replace: true });
    }
    else if (otpVerifyStatus === 1) {
      navigate('/login')
    }
    else if (adsStatusDetails?.canAdShow === false) {
      navigate('/venue-info')
    }
  }, [adsStatusDetails?.canAdShow, isLoggedIn, navigate, otpVerifyStatus]);

  return <>{children}</>;
};

export default AuthGuard;
