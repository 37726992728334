import { createSlice } from "@reduxjs/toolkit";
import {
  API_ADD_COMMUNICATION,
  API_CHANGE_COMMUNICATION_STATUS, API_CHANGE_DEAL_STATUS,
  API_CHECK_COMMUNICATION_AVAILABILITY,
  API_CHECK_DEAL_WEEK_AVAILABILITY,
  API_COMMUNICATIONS_LIST,
  API_COMMUNICATION_DELETE, API_COMMUNICATION_EDIT, API_DEAL_ADD,
  API_DEAL_DELETE,
  API_DEAL_EDIT,
  API_DEAL_LIST,
  API_DEAL_READMPTION_HISTORY,
  API_GET_COMMUNICATION,
  API_GET_COUNTER_DETAILS,
  API_GET_DEAL,
  API_GET_SCHEDULE_LIST,
  API_REACTIVATE_COMMUNICATION, API_REACTIVATE_DEAL,
} from "../constants/index";

// const projectName = "GOAT-Grub";

const initialState = {
  isLoading: false,
  isLoggedIn: false,
  offerDetail: {},
  sidebarShow: true,
  offerList: { data: [], recordsTotal: 0 },
  readmptionList: { data: [], recordsTotal: 0 },
  scheduleList: { data: [] },
  counterDetails: { data: {} },
  page: 0,
  limit: 10,
};

//Get offer List Action
export const getOfferList = (data) => ({
  type: "API",
  payload: {
    url: API_DEAL_LIST,
    method: "POST",
    data: data,
    hideLoader: true,
    success: (data) => ({
      type: "getOfferList/success",
      payload: data,
    }),
    error: (data) => ({
      type: "getOfferList/fail",
      payload: {},
    }),
  },
});
//add deal
export const addDeal = (data) => ({
  type: "API",
  payload: {
    url: API_DEAL_ADD,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "addOffer/success",
      payload: data,
    }),
    error: (data) => ({
      type: "addOffer/fail",
      payload: {},
    }),
  },
});

export const reactivateDeal = (data) => ({
  type: "API",
  payload: {
    url: API_REACTIVATE_DEAL,
    method: "PATCH",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "reactivateOffer/success",
      payload: data,
    }),
    error: (data) => ({
      type: "reactivateOffer/fail",
      payload: {},
    }),
  },
});

//Get offer Detail Action
export const getofferDetail = (data) => ({
  type: "API",
  payload: {
    url: API_GET_DEAL,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "getOfferDetail/success",
      payload: data,
    }),
    error: (data) => ({
      type: "getOfferDetail/fail",
      payload: {},
    }),
  },
});

//User Edit Action
export const offerEditAction = (data) => ({
  type: "API",
  payload: {
    url: API_DEAL_EDIT,
    method: "PATCH",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "offerEdit/success",
      payload: data,
    }),
    error: (data) => ({
      type: "offerEdit/fail",
      payload: {},
    }),
  },
});

//User Delete Action
export const offerDeleteAction = (data) => ({
  type: "API",
  payload: {
    url: API_DEAL_DELETE,
    method: "DELETE",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "deleteOffer/success",
      payload: data,
    }),
    error: (data) => ({
      type: "deleteOffer/fail",
      payload: {},
    }),
  },
});

export const offerActiveInactiveAction = (data) => ({
  type: "API",
  payload: {
    url: API_CHANGE_DEAL_STATUS,
    method: "PATCH",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "offerActiveInactive/success",
      payload: data,
    }),
    error: (data) => ({
      type: "offerActiveInactive/fail",
      payload: {},
    }),
  },
});

//subscriptionHistory
export const getReadmptionList = (data) => ({
  type: "API",
  payload: {
    url: API_DEAL_READMPTION_HISTORY,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "getReadmptionList/success",
      payload: data,
    }),
    error: (data) => ({
      type: "getReadmptionList/fail",
      payload: {},
    }),
  },
});

//Get communications List Action
export const getCommunicationsList = (data) => ({
  type: "API",
  payload: {
    url: API_COMMUNICATIONS_LIST,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "getCommunicationsList/success",
      payload: data,
    }),
    error: (data) => ({
      type: "getCommunicationsList/fail",
      payload: {},
    }),
  },
});

//add deal
export const addCommunication = (data) => ({
  type: "API",
  payload: {
    url: API_ADD_COMMUNICATION,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "addCommunication/success",
      payload: data,
    }),
    error: (data) => ({
      type: "addCommunication/fail",
      payload: {},
    }),
  },
});
export const getCommunication = (data) => ({
  type: 'API',
  payload: {
    url: API_GET_COMMUNICATION,
    method: 'POST',
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'getCommunication/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'getCommunication/fail',
      payload: {},
    }),
  },
})
export const reactivateCommunication = (data) => ({
  type: 'API',
  payload: {
    url: API_REACTIVATE_COMMUNICATION,
    method: 'PATCH',
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'reactivateCommunication/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'reactivateCommunication/fail',
      payload: {},
    }),
  },
})
export const communicationDeleteAction = (data) => ({
  type: 'API',
  payload: {
    url: API_COMMUNICATION_DELETE,
    method: 'DELETE',
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'deleteCommunication/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'deleteCommunication/fail',
      payload: {},
    }),
  }
})
export const communicationActiveInactiveAction = (data) => ({
  type: 'API',
  payload: {
    url: API_CHANGE_COMMUNICATION_STATUS,
    method: 'PATCH',
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: 'communicationActiveInactive/success',
      payload: data,
    }),
    error: (data) => ({
      type: 'communicationActiveInactive/fail',
      payload: {},
    }),
  }
})

export const communicationEditAction = (data) => ({
  type: "API",
  payload: {
    url: API_COMMUNICATION_EDIT,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "communicationEdit/success",
      payload: data,
    }),
    error: (data) => ({
      type: "communicationEdit/fail",
      payload: {},
    }),
  },
});


export const getCounterDetails = () => ({
  type: "API",
  payload: {
    url: API_GET_COUNTER_DETAILS,
    method: "GET",
    hideLoader: false,
    success: (data) => ({
      type: "counterDetails/success",
      payload: data,
    }),
    error: (data) => ({
      type: "counterDetails/fail",
      payload: {},
    }),
  },
});
export const getSchedualList = (data) => ({
  type: "API",
  payload: {
    url: API_GET_SCHEDULE_LIST,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "getScheduleList/success",
      payload: data,
    }),
    error: (data) => ({
      type: "getScheduleList/fail",
      payload: {},
    }),
  },
});
export const getDealWeekAvailability = (data) => ({
  type: "API",
  payload: {
    url: API_CHECK_DEAL_WEEK_AVAILABILITY,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "checkDealWeekAvailability/success",
      payload: data,
    }),
    error: (data) => ({
      type: "checkDealWeekAvailability/fail",
      payload: {},
    }),
  },
});
export const getCommunicationAvailability = (data) => ({
  type: "API",
  payload: {
    url: API_CHECK_COMMUNICATION_AVAILABILITY,
    method: "POST",
    data: data,
    hideLoader: false,
    success: (data) => ({
      type: "checkCommunicationAvailability/success",
      payload: data,
    }),
    error: (data) => ({
      type: "checkCommunicationAvailability/fail",
      payload: {},
    }),
  },
});


// Reducer
const getOfferSlice = createSlice({
  name: "getOfferList",
  initialState: initialState,
  reducers: {
    loaderChange: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase("set", (state, action) => {
      state.sidebarShow = action.payload;
    });
    builder.addCase("getOfferList/success", (state, action) => {
      state.offerList = action.payload.data;
    });
    builder.addCase("getOfferList/fail", (state, action) => {
      state.offerList = { data: [], recordsTotal: 0 };
      state.isLoggedIn = false;
    });
    builder.addCase("addOffer/success", (state, action) => {
      state.offerList = action.payload.data;
    });
    builder.addCase("addOffer/fail", (state, action) => {
      state.offerList = { data: [], recordsTotal: 0 };
      state.isLoggedIn = false;
    });
    builder.addCase("getOfferDetail/success", (state, action) => {
      state.offerDetail = action.payload.data;
    });
    builder.addCase("getOfferDetail/fail", (state, action) => {
      state.offerDetail = {};
      state.isLoggedIn = false;
    });
    builder.addCase("offerEdit/success", (state, action) => {
      state.offerDetail = action.payload.data;
    });
    builder.addCase("offerEdit/fail", (state, action) => {
      state.offerDetail = {};
      state.isLoggedIn = false;
    });
    builder.addCase("getReadmptionList/success", (state, action) => {
      state.readmptionList = action.payload.data;
    });
    builder.addCase("getReadmptionList/fail", (state, action) => {
      state.readmptionList = { data: [], recordsTotal: 0 };
      state.isLoggedIn = false;
    });

    builder.addCase("getCommunicationsList/success", (state, action) => {
      state.communicationsList = action.payload.data;
    });
    builder.addCase("getCommunicationsList/fail", (state, action) => {
      state.communicationsList = { data: [], recordsTotal: 0 };
      state.isLoggedIn = false;
    });
    builder.addCase("counterDetails/success", (state, action) => {
      state.counterDetails = action.payload.data;
    });
    builder.addCase("counterDetails/fail", (state, action) => {
      state.counterDetails = { data: {} };
      state.isLoggedIn = false;
    });
    builder.addCase("getScheduleList/success", (state, action) => {
      state.scheduleList = action.payload.data;
    });
    builder.addCase("getScheduleList/fail", (state, action) => {
      state.scheduleList = { data: [] };
      state.isLoggedIn = false;
    });
    builder.addCase("checkDealWeekAvailability/success", (state, action) => {
      state.dealWeekAvailability = action.payload.data;
    });
    builder.addCase("checkDealWeekAvailability/fail", (state, action) => {
      state.dealWeekAvailability = { data: [] };
      state.isLoggedIn = false;
    });
    builder.addCase("checkCommunicationAvailability/success", (state, action) => {
      state.communicatoinAvailability = action.payload.data;
    });
    builder.addCase("checkCommunicationAvailability/fail", (state, action) => {
      state.communicatoinAvailability = { data: [] };
      state.isLoggedIn = false;
    });
    
  },
});

export const { loaderChange } = getOfferSlice.actions;
export default getOfferSlice.reducer;
