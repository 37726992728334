import styled from 'styled-components';

export const Wrapper = styled.div`
  .ant-spin-dot-item {
            background-color: #f6883d;
            font-weight:900;
        }
        .ant-spin-text {
            color: #f6883d;
            font-weight: bold;
        }
        .spinner-wrap {
    position: fixed;
    background-color: #f0f0f0;

    /* background-color: rgba(0, 0, 0, 0.5); */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1111111111;

    .spinner {
        width: 100px;
        height: 100px;
        position: fixed;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: 50%;
        bottom: 0;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);

        .double-bounce1,
        .double-bounce2 {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            background-color: #ffffff;
            opacity: 0.6;
            position: absolute;
            top: 0;
            left: 0;
            -webkit-animation: sk-bounce 2s infinite ease-in-out;
            animation: sk-bounce 2s infinite ease-in-out;
        }

        .double-bounce2 {
            -webkit-animation-delay: -1s;
            animation-delay: -1s;
        }
    }
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0);
    }

    50% {
        -webkit-transform: scale(1);
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
    }

    50% {
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}

`;
