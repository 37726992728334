// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { toast } from "react-toastify";
import { FIREBASE_APP_ID, FIREBASE_AUTH_DOMAIN, FIREBASE_KEY, FIREBASE_MEASUREMENT_ID, FIREBASE_PROJECTID, FIREBASE_SENDER_ID, FIREBASE_STORAGE_BUCKET, FIREBASE_VAPID_KEY } from "../constants";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: FIREBASE_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECTID,
    storageBucket: FIREBASE_STORAGE_BUCKET,
    messagingSenderId: FIREBASE_SENDER_ID,
    appId: FIREBASE_APP_ID,
    measurementId: FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

export const fetchToken = async (setTokenFound, setFmcToken) => {

    return getToken(messaging, { vapidKey: FIREBASE_VAPID_KEY })
        .then((currentToken) => {
            if (currentToken) {
                setFmcToken(currentToken);
                setTokenFound(true);
            } else {
                setFmcToken("");
                setTokenFound(false);
            }
        })
        .catch((err) => {
            console.log("error occured", err);
        });
};


export const onMessageListener = async () => {
    return new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            toast.info(
                <div>
                    <div>{payload?.data?.title}</div>
                    <br />
                    <div>{payload?.data?.body}</div>
                </div>,
                {
                    duration: 4000,
                    position: "top-right",
                }
            );
            resolve(payload);
        });
    });
}
