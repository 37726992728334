import { createGlobalStyle } from "styled-components";

export const CommonFormFiled = createGlobalStyle`

    .ant-select-selection {
        :placeholder-shown {
            font-family: "SF Pro Display";font-weight: 500;font-size: 16px;color:#B3B3B3 !important;
        }
        &:placeholder-shown {
            font-family: "SF Pro Display";font-weight: 500;font-size: 16px;color:#B3B3B3 !important;
        }
        ::placeholder {
            font-family: "SF Pro Display";font-weight: 500;font-size: 16px;color:#B3B3B3 !important;
        }
        color: black !important;
        &::-webkit-input-placeholder {
            font-family: "SF Pro Display";font-weight: 500;font-size: 16px;color:#B3B3B3 !important;
        }
        &:placeholder {
            font-family: "SF Pro Display";font-weight: 500;font-size: 16px;color:#B3B3B3 !important;
        }
        :placeholder {
            font-family: "SF Pro Display";font-weight: 500;font-size: 16px;color:#B3B3B3 !important;
        }
        ::-webkit-input-placeholder {
            font-family: "SF Pro Display";font-weight: 500;font-size: 16px;color:#B3B3B3 !important;
        }
    }

    ::-webkit-input-placeholder {
        font-family: "SF Pro Display";font-weight: 500;font-size: 16px;color:#B3B3B3 !important;   
    }

    .ant-form-item{
        margin:0;
        .ant-form-item-label {
            label{
                height:auto;
                font-family: 'SF Pro Display';
                font-weight: 500;
                font-size: 15px;
                color: #242424;
                /* width: 100%; */
                &:before{
                    display: none !important;
                }
                span{
                    color:var(--theme--color);
                    margin-left: 5px;
                    /* width: 100%;
                    justify-content: end; */
                }
            }
        }
        .ant-form-item-control{
            .ant-input,.pac-target-input,.ant-picker{
                background-color: #F5F7F9 !important;
                border-radius: 8px;
                padding: 6px 12px;
                border: none;
                box-shadow: none;
                font-family: "SF Pro Display";
                font-weight: 500;
                font-size: 16px;
                letter-spacing: 0.14px;
                color: var(--black--color);
                height: 56px;
                width:100%;
                &:focus{
                    border: 1px solid var(--theme--color) !important;
                    box-shadow:none !important;
                }
            }
            .pac-target-input{
                width: 100%;
            }
            .ant-input-affix-wrapper.ant-input-password  {
                border: none !important;
                box-shadow: none !important;
                padding: 0;
                position: relative;
                border-radius: 8px;
                .ant-input {
                    padding: 6px 40px 6px 12px;
                }
                .ant-input-suffix{
                    position: absolute;
                    right: 17px;
                    top: 0;
                    bottom: 0;
                }
            }
            .ant-select{
                &.ant-select-focused{
                    .ant-select-selector{
                        box-shadow: none !important;
                        border:none !important;
                    }
                }
                .ant-select-selector{
                    background-color: #F5F7F9;
                    border-radius: 8px;
                    padding: 6px 40px 6px 12px;
                    border: none;
                    box-shadow: none;
                    font-family: "SF Pro Display";
                    font-weight: 500;
                    font-size: 16px;
                    letter-spacing: 0.14px;
                    color: var(--black--color);
                    height: 56px;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    .ant-select-selection-search-input{
                        height: 100%;
                        font-size: 16px;
                    }                    
                }
                .ant-select-arrow{
                    right:20px;
                }
            }
        }
    }
    .ant-btn.ant-btn-primary{
        background-color: var(--theme--color) !important;
        border-radius: 8px;
        font-family: "SF Pro Display";
        font-weight: 500;
        font-size: 15px;
        color:var(--white--color);
        padding: 16px 40px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        &.ant-small{
            padding: 12px 24px;
            font-family: 'SF Pro Display';
            font-weight: 500;
            font-size: 15px;
            color: #FFFFFF;
            svg{
                margin:0 12px 0 0;
            }
        }
        svg{
            margin-left: 12px;
        }
        &:hover,&:focus{
            background-color: var(--theme--color) !important; 
        }
        &.disable{
            background-color: #F9B786 !important;
        }
    }
    .ant-btn.ant-btn-default{
        padding: 0;
        border: none;
        box-shadow: none;
        font-family: 'SF Pro Display';
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.14px;
        color: var(--theme--color);
        outline: none !important;
        &:hover,&:focus{
            color: var(--theme--color) !important; 
        }
    }

    /* --- Radio --- */
    .ant-radio-group{
        .ant-radio-wrapper {
            font-family: 'SF Pro Display';
            font-weight: 500;
            font-size: 12px;
            letter-spacing: 0.15px;
            color: #1F2D4A;
            .ant-radio{
                .ant-radio-inner{
                    width:20px;
                    height:20px;
                    border: 1px solid #A6A6A6;
                    &:after{
                        background-color: rgb(246, 140, 61);
                        /* margin-block-start: -7.8px; */
                    }
                }
                &.ant-radio-checked{
                    .ant-radio-inner{
                        border: 1px solid var(--theme--color);
                        background-color: var(--white--color);
                        &:after{
                            transform: scale(0.85);
                            transform: scale(0.85);
                            -ms-transform: scale(0.85);
                            -moz-transform: scale(0.85);
                            -webkit-transform: scale(0.85);
                        }
                    }
                }
            }
        }
    }

    /* --- CheckBox --- */
    .ant-checkbox-wrapper{
        .ant-checkbox:after {display:none;}
        .ant-checkbox-indeterminate .ant-checkbox-inner:after{display:none;}
        .ant-checkbox-inner{
            width: 18px;
            height: 18px;
            border: 3px solid #6B7280 !important;
            background-color:var(--white--color) !important;
            &:after{
                width: 6px;
                height: 10px;
                inset-inline-start: 2px;
            }
        }
        .ant-checkbox-checked{
            .ant-checkbox-inner{
                background-color: var(--theme--color) !important;
                border-color: var(--theme--color) !important;
            }
        }
    }

    /* --- Upload --- */
    .ant-upload-wrapper.ant-upload-picture-card-wrapper {
        .ant-upload.ant-upload-select {
            border: 2px dashed #DBE0E4 !important;
            background-color:var(--white--color);
            width: 114px;
            height: 114px;
            .sub-title{
                font-family: 'SF Pro Display';
                font-weight: 500;
                font-size: 15px;
                color: #B3B3B3;
                margin-left:10px;
            }
        }
        .ant-upload-list.ant-upload-list-picture-card{
            .ant-upload-list-item-container{
                width: 114px;
                height: 146px;
                .ant-upload-list-item{
                    border: 2px dashed #DBE0E4;
                    display: flex;
                    align-items: flex-start;
                    &:before{
                        display:none;
                    }
                    .ant-upload-list-item-thumbnail{
                        width: 97px;
                        height: 97px;
                        img{
                            object-fit: cover;
                        }
                    }
                    .ant-upload-list-item-actions{
                        bottom:0;
                        opacity:1;
                        padding: 10px 0;
                        .ant-upload-list-item-action{
                            opacity: 1;
                        }
                        .ant-btn{
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            height: 100%;
                            background: none;
                            .delete-btn{
                                display: flex;
                                align-items: center;
                                font-family: 'SF Pro Display';
                                font-weight: 500;
                                font-size: 15px;
                                line-height: 18px;
                                color: #D14343;
                                svg{
                                    margin-right: 8px;
                                }
                            }
                        }
                        .anticon-eye{
                            display: none;
                        }
                        
                    }
                }
            }
        }
        &.cover-upload{
            .ant-upload.ant-upload-select {
                width: 100%;
                height: 260px;
            }
            .ant-upload-list.ant-upload-list-picture-card{
                .ant-upload-list-item-container{
                    width: 100%;
                    height: 320px;
                    .ant-upload-list-item {
                        .ant-upload-list-item-thumbnail{
                            width: 100%;
                            height: 260px;
                            img{
                                object-fit: cover;
                            }
                        }
                    }
                }
            }
        }
        &.profile-upload{
            .ant-upload.ant-upload-select {
                width: 56px;
                height: 56px;
                margin: 0;
                border-radius: 100%;
                position: relative;
                .upload-btn {
                    position: absolute;
                    right: -5px;
                    bottom: -5px;
                }
            }
            .ant-upload-list.ant-upload-list-picture-card{
                .ant-upload-list-item-container{
                    width: 56px;
                    height: 56px;
                    margin: 0;                    
                    .ant-upload-list-item {
                        border: none;
                        border-radius: 100%;
                        padding: 0;
                        .ant-upload-list-item-thumbnail{
                            object-fit:cover;
                            border-radius: 100%;
                            width: 56px;
                            height: 56px;
                        }
                    }
                    .ant-upload-list-item-actions{
                        padding:0;
                        .ant-btn{
                            margin-left: 25px;
                            .delete-btn{
                                font-size:0;
                            }
                        }
                    }
                }
            }
        }
    } 
    .ant-upload-wrapper.ant-upload-picture-card-wrapper::after{
        padding-bottom:6px ;
    }
    
    .ant-select-dropdown{
        padding: 9px 0;
        &.view-pop{
            min-width: 200px !important;
            .ant-select-item{
                text-align: center;
            }
        }
        &.search-hit-popup{
            /* left: 1560px !important; */
            min-width: 195px !important;
            max-width: 200px !important;
            padding: 9px 0;
            background-color: #FFFFFF;
            border: 1px solid #E4E4E7;
            box-shadow: 0px 10px 15px -3px rgba(100, 116, 139, 0.12), 0px 4px 6px -2px rgba(100, 116, 139, 0.05);
            .ant-select-item-option{
                padding: 10px 20px;
                text-align: center;
                /* &.ant-select-item-option-active{
                    background-color: transparent;
                } */
                .ant-select-item-option-content{
                    font-family: 'SF Pro Display';
                    font-size: 17px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 25px;
                    color: #1F2D4A;
                }
            }
        }
        .ant-select-item{
            background-color: var(--white--color);
            padding: 5px 35px;
            min-height: 45px;
            align-items: center;
            border-radius: 0;
            .ant-select-item-option-content{
                font-family: 'SF Pro Display';
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: #1F2D4A;
            }
            &.ant-select-item-option-active,&.ant-select-item-option-selected{
                background-color: rgba(55, 65, 81, 0.08);
            }
            
        }
    }

`;