import React from "react";
import { ModalWrapper } from "./ModalStyle";
import { CloseIcon } from "../../../svg";

const Modal = (props) => {
  const {
    open,
    title,
    width,
    className,
    onOk,
    onCancel,
    footer,
    centered,
    children,
    getContainer,
    
  } = props;

  return (
    <>
      <ModalWrapper
        open={open}
        title={title}
        width={width}
        centered={centered}
        className={`common-modal  ${className}`}
        onOk={onOk}
        onCancel={onCancel}
        footer={footer}
        closeIcon={<CloseIcon />}
        getContainer={getContainer}
      >
        {children}
      </ModalWrapper>
    </>
  );
};

export default React.memo(Modal);
