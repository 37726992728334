import { createGlobalStyle } from "styled-components";

export const CommonTabbing = createGlobalStyle`

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #242424;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}
.ant-tabs .ant-tabs-ink-bar{
    background:#242424;
}
.ant-tabs .ant-tabs-tab{
    color: #6B7280;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}
.ant-tabs .ant-tabs-tab:hover{
    color:#242424 ;
}
.ant-tabs-nav{
    margin-bottom:65px !important;
}
   
`;
