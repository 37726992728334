import React from 'react';
import { Wrapper } from '../Login/loginCss';
import { toAbsoluteUrl } from '../../utils';
import { Button, Col, Form, Row } from 'antd';
import { RenderPassword } from '../../utils/FormField';
import { RightIconWhite } from '../../svg';
import { toast } from 'react-toastify';
import { SET_USER_NEW_PASSWORD } from '../../Redux/VenueSlice';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';


const UserNewPassword = () => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const handleLogin = (values) => {
        const data = {
            "userId": location.pathname.split('/')[2],
            "password": values.password
        }
        dispatch(SET_USER_NEW_PASSWORD(data)).then((res) => {
            navigate('/set-password');
        }).catch((error) => {
            toast.error(error.message)
        })
    }
    return (
        <>
            <Wrapper className='login'>
                <div className='login-wrap'>
                    <div className='login-form'>
                        <div className='text-center'>
                            <img src={toAbsoluteUrl("/images/login-logo.png")} alt="login-logo" />
                            <h2 className='title'>Set New  password</h2>
                            {/* <p className='desc'>Enter your details to Change password</p> */}
                        </div>
                        <Form onFinish={handleLogin} form={form} autoComplete="off">
                            <Row gutter={[30, 20]}>

                                <RenderPassword
                                    name="password"
                                    col={{ xs: 24 }}
                                    placeholder="Set New Password"
                                    label="Set New Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Password is required',
                                        },
                                        {
                                            pattern: /^.{8,16}$/,
                                            message: 'New Password must be 8-16 characters long'
                                        }
                                    ]}
                                />

                                <Col xs={24}>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className='w-100'
                                    >
                                        Submit<RightIconWhite />
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </div>
            </Wrapper>
        </>
    );
}

export default UserNewPassword;