import React, { Suspense, useEffect } from "react";
import axios from "axios";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "../Redux/store";
import Routes from "./routes";
import GlobalStyle from '../style/global';
import ScrollToTop from '../components/common/scroll';
import Loader from "../components/common/loader";
import { setupAxios } from "../utils";
import { ErrorBoundary } from "../components/Error";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { onMessageListener } from "../utils/firebase";
import { isSupported } from "firebase/messaging";
import { RestaurantProvider } from "../utils/restaurantContext";

const { PUBLIC_URL } = process.env;

setupAxios(axios, store);

function isIos() {
  return typeof window === "undefined" ? true : false
}
const AppContainer = () => {
  useEffect(() => {
    if (isIos()) {
      isSupported().then(() => {
        onMessageListener()
          .then((payload) => {
            // openNotification(payload, "top");
          })
      })
    } else {
      onMessageListener()
    }

  }, [])

  return (
    <ErrorBoundary>
      <Provider store={store}>
      <RestaurantProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Suspense fallback={<Loader isSuspense />}>
          <Loader>
            <BrowserRouter basename={PUBLIC_URL}>
              <ScrollToTop>
                <GlobalStyle />
                <Routes />
              </ScrollToTop>
            </BrowserRouter>
          </Loader>
        </Suspense>
        </RestaurantProvider>
      </Provider>
    </ErrorBoundary>
  )
};

export default AppContainer;
