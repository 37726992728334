import { configureStore } from '@reduxjs/toolkit'
import reduxApiMiddleware from './Middleware'
import thunk from 'redux-thunk'
import AuthSlice from './AuthSlice'
import VenueSlice from './VenueSlice'
import AnalyticSlice from './AnalyticSlice'
import OffersSlice from './OffersSlice'
import AdvertiseSlice from './AdvertiseSlice'

export const store = configureStore({
    reducer: {
        auth: AuthSlice,
        venue: VenueSlice,
        analytic: AnalyticSlice,
        offers: OffersSlice,
        adversite:AdvertiseSlice
       
    },
    middleware: [thunk, reduxApiMiddleware]
}) 