// import React, { createContext, useState, useContext } from 'react';

// const RestaurantContext = createContext({
//     restaurantId: null,
//     setRestaurantId: () => {}
// });

// export const useRestaurant = () => useContext(RestaurantContext);

// export const RestaurantProvider = ({ children }) => {
//     const [restaurantIdContext, setRestaurantIdContext] = useState(null);

//     return (
//         <RestaurantContext.Provider value={{ restaurantIdContext, setRestaurantIdContext }}>
//             {children}
//         </RestaurantContext.Provider>
//     );
// };
// RestaurantContext.js
import React, { createContext, useState, useContext, useEffect } from "react";
import { SELECTED_RESTAURANT_ID } from "../constants";

// Create the context
const RestaurantContext = createContext();

// Create a custom hook to use the context
export const useRestaurant = () => useContext(RestaurantContext);

// Provider component to wrap around your app
export const RestaurantProvider = ({ children }) => {
  const [restaurantIdContext, setRestaurantIdContext] = useState(() => {
    // Retrieve the restaurant ID from localStorage when the app loads
    const savedRestaurantId = localStorage.getItem(SELECTED_RESTAURANT_ID);
    return savedRestaurantId ? savedRestaurantId : null;
  });

  // Save restaurant ID to localStorage whenever it changes
  useEffect(() => {
    if (restaurantIdContext) {
      localStorage.setItem(SELECTED_RESTAURANT_ID, restaurantIdContext);
    }
  }, [restaurantIdContext]);

  return (
    <RestaurantContext.Provider value={{ restaurantIdContext, setRestaurantIdContext }}>
      {children}
    </RestaurantContext.Provider>
  );
};

