import { createSlice } from "@reduxjs/toolkit";
import { ADD_CARD, API_ADD_Adversite, API_ADD_CARD, API_CancelAdvertise, API_DELETE_Adversite, API_lISTallCards, API_LIST_Adversite, API_UPDATE_CARD, LISTcards, lIST_Adversite, API_DELETE_CARD, API_GET_SUBSCRIPTION_PLAN, API_SUBSCRIBEPLAN, API_GET_CREDIT_PLAN, API_BUY_CREDITS } from "../constants";
import { toast } from "react-toastify";


const initialState = {

};

export const addCard = (data) => ({
    type: "API",
    payload: {
        url: API_ADD_CARD,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: ADD_CARD,
            payload: data,
        }),
        error: (error) => {
            toast.error(error.message)
        }
    },
});
export const DeleteCard = (data) => ({
    type: "API",
    payload: {
        url: API_DELETE_CARD,
        method: "POST",
        data: data,
        hideLoader: false
    },
});
export const addAdversite = (data) => ({
    type: "API",
    payload: {
        url: API_ADD_Adversite,
        method: "POST",
        data: data,
        hideLoader: false,
    },
});
export const lISTAdversite = (data) => ({
    type: "API",
    payload: {
        url: API_LIST_Adversite,
        method: "POST",
        data: data,
        hideLoader: false,
        success: (data) => ({
            type: lIST_Adversite,
            payload: data,
        })
    },
});
export const DeleteAdversite = (data) => ({
    type: "API",
    payload: {
        url: API_DELETE_Adversite,
        method: "DELETE",
        data: data,
        hideLoader: false
    },
});
export const CancelAdversite = (data) => ({
    type: "API",
    payload: {
        url: API_CancelAdvertise,
        method: "POST",
        data: data,
        hideLoader: false
    },
});
export const ListAllCards = (data) => ({
    type: "API",
    payload: {
        url: API_lISTallCards,
        method: "POST",
        data: data,
        hideLoader: true,
        success: (data) => ({
            type: LISTcards,
            payload: data,
        }),
        error: (error) => {
            toast.error(error.message)
        }
    },
});
export const UPDATECard = (data) => ({
    type: "API",
    payload: {
        url: API_UPDATE_CARD,
        method: "POST",
        data: data,
        hideLoader: false
    },
});

export const CreateSubscribePlan = (data) => ({
    type: "API",
    payload: {
        url: API_SUBSCRIBEPLAN,
        method: "POST",
        data: data,
        hideLoader: false
    },
});


export const getSubscriptionPlan = () => ({
    type: "API",
    payload: {
        url: API_GET_SUBSCRIPTION_PLAN,
        method: "GET",
        hideLoader: false,
        success: (data) => ({
            type: "GET_SUBSCRIPTION_PLAN_LIST",
            payload: data,
        }),
        error: (error) => {
            toast.error(error.message)
        }
    },
});

export const getcreditPlans = () => ({
    type: "API",
    payload: {
        url: API_GET_CREDIT_PLAN,
        method: "GET",
        hideLoader: true,
        success: (data) => ({
            type: "GET_CREDIT_PLAN_LIST",
            payload: data,
        }),
        error: (error) => {
            toast.error(error.message)
        }
    },
});

export const buyCredit = (data) => ({
    type: "API",
    payload: {
        url: API_BUY_CREDITS,
        method: "POST",
        data: data,
        hideLoader: false
    },

});



const AdvertiseSlice = createSlice({
    name: "AnalyticSlice",
    initialState: initialState,
    extraReducers: (builder) => {
        builder.addCase(lIST_Adversite, (state, action) => {
            state.lISTAdversite = action.payload.data
        });
        builder.addCase(LISTcards, (state, action) => {
            state.Listcards = action.payload.data.data
        });
        builder.addCase("GET_SUBSCRIPTION_PLAN_LIST", (state, action) => {
            state.subscriptionPlanList = action.payload.data
        });
        builder.addCase("GET_CREDIT_PLAN_LIST", (state, action) => {
            state.creditPlanList = action.payload.data
        });


    },
});

export const { loaderChange } = AdvertiseSlice.actions;
export default AdvertiseSlice.reducer;
