import React from "react";
import Variable from "./variable";
import Globals from "./col";
import Common from "./common";
import Flex from "./flex";
import Reset from "./reset";
import Theme from "./theme";
import { CommonTable } from "./components/table";
import { CommonFormFiled } from "./components/formfiled";
import { CommonTabbing } from "./components/tabbing";

const GlobalStyle = () => {
  return (
    <>
      <Variable />
      <Globals />
      <Common />
      <Flex />
      <Reset />
      <CommonTable />
      <CommonTabbing />
      <CommonFormFiled />
      <Theme />
    </>
  );
};

export default GlobalStyle;
