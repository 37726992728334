import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row, Upload } from "antd";
import { toast } from "react-toastify";
import { toAbsoluteUrl } from "../../utils";
import {
  RenderInput,
  RenderSelect,
  RenderTextArea,
  RenderTimePicker,
} from "../../utils/FormField";
import { getRestaurantIdAndName } from "../../Redux/AnalyticSlice";
import { addCommunication } from "../../Redux/OffersSlice";
import { MinusIcon, SendIcon, UploadIcon } from "../../svg";
import { Wrapper } from "./style";
import dayjs from "dayjs";
import moment from "moment-timezone";
import Modal from "../../components/common/Modal";
import CustomDatePicker from "./CustomDatePicker";
import { useRestaurant } from "../../utils/restaurantContext";


const AddCommunications = ({ isNext }) => {

  const { restaurantIdContext, setRestaurantIdContext } = useRestaurant();

  const navigate = useNavigate();
  let dispatch = useDispatch();
  const [form] = Form.useForm();
  const location = useLocation();
  let restaurantList =
    useSelector((state) => state.analytic.restaurantList) || [];
  const [restaurantId, setRestaurantId] = useState(null);
  const [restaurantImage, setrestaurantImage] = useState([]);
  const [restaurantImageError, setrestaurantImageError] = useState(false);
  const [date, setDate] = useState({ startDate: "", endDate: "" });
  const [loading, setLoading] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [restaurantTimezone, setRestaurantTimezone] = useState("");

  const [btndisabled, setbtndisabled] = useState(true);

  const handleChange = ({ file, fileList }) => {
    setrestaurantImageError(false);
    setrestaurantImage(fileList);
  };

  const uploadCoverButton = (
    <div>
      <img src={toAbsoluteUrl("/images/camera.png")} alt="camera" />
      <div className="d-flex align-items-center">
        <UploadIcon />
        <span className="sub-title">Add Image</span>
      </div>
    </div>
  );

  useEffect(() => {
    dispatch(getRestaurantIdAndName())
      .then((response) => {
        const firstRestaurant = response?.data?.restaurantList[0];

        const findTimezoneForSelectedRestaurant = response?.data?.restaurantList.find(
          (restaurant) => restaurant._id === restaurantIdContext
        );

        form.setFieldsValue({
          Location: findTimezoneForSelectedRestaurant?._id || firstRestaurant?._id,
        });
        setRestaurantId(findTimezoneForSelectedRestaurant?._id || firstRestaurant?._id);
        setRestaurantTimezone(findTimezoneForSelectedRestaurant?.timeZone || firstRestaurant?.timeZone);

      })
      .catch((error) => toast.error(error.message));
  }, [dispatch, form, restaurantIdContext]);

  const handleChangLocation = (value) => {
    const selectedRestaurant = restaurantList.find(
      (restaurant) => restaurant._id === value
    );
    if (selectedRestaurant) {
      setRestaurantId(selectedRestaurant?._id);
      setRestaurantTimezone(selectedRestaurant?.timeZone);
      setRestaurantIdContext(selectedRestaurant?._id)
    }
  }

  const handleSubmit = (values) => {
    let formData = new FormData();
    let dt = dayjs(values.deliveryDate).format("YYYY-MM-DD");
    formData.append("restaurantId", restaurantId);
    formData.append("title", values.title.trim());
    formData.append("body", values.body);

    let selectedTime = values.deliveryTime.$d; // User-selected time
    let timeInRestaurantTZ = moment.tz(selectedTime, restaurantTimezone);
    let utcCommunicatiomTime = timeInRestaurantTZ.utc().format("HH:mm");

    formData.append("deliveryTime", utcCommunicatiomTime);
    formData.append("deliveryDate", dt);
    if (restaurantImage.length > 0) {
      formData.append("image", restaurantImage[0].originFileObj);
    }
    setLoading(true);
    dispatch(addCommunication(formData))
      .then((response) => {
        if (response) {
          setModalOpen(true);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => setLoading(false));
  };
  const onValuesChange = (changedValues, allValues) => {
    if (
      allValues.title &&
      allValues.body &&
      allValues.deliveryDate &&
      allValues.deliveryTime
    )
      setbtndisabled(false);
    else setbtndisabled(true);
  };
  const handleModalOk = () => {
    setModalOpen(false);
    if (isNext) navigate(`/schedule`);
    else navigate(`/communications/active`);
  };

  const handleDelete = () => setrestaurantImage([]);
  const props = {
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: "Download",
      showRemoveIcon: true,
      removeIcon: restaurantImage.length > 0 && (
        <span onClick={handleDelete} className="delete-btn">
          <MinusIcon />
          Delete
        </span>
      ),
    },
  };
  return (
    <>
      <Wrapper className="deals">
        <div className="shadow-paper">
          <div className="page-header page-sub-header adddeals-header">
            <h2>Add A Communication</h2>
          </div>
          <div className="custom-select adddeals-select">
            <Form form={form} name="something">
              <RenderSelect
                col={{ xs: 24 }}
                name="Location"
                placeholder="Select Location"
                rules={[
                  {
                    required: true,
                    message: "Location is required!",
                  },
                ]}
                showSearch={true}
                onChange={handleChangLocation}
                optionLabel={restaurantList}
              />
            </Form>
          </div>
          <div className="shadow-form">
            <Form
              form={form}
              onFinish={handleSubmit}
              id={"add-com-from"}
              onValuesChange={onValuesChange}
            >
              <Row gutter={[50, 0]}>
                <Col lg={12} xs={24}>
                  <Row gutter={[0, 36]}>
                    <RenderInput
                      col={{ lg: 24, xs: 24 }}
                      name="title"
                      placeholder="Communication Title"
                      label="Communication Title"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (!value || !value.trim()) {
                              return Promise.reject(
                                new Error(
                                  "Communication Title field is required!"
                                )
                              );
                            }
                            if (value.trim().length < 2) {
                              return Promise.reject(
                                new Error(
                                  "Communication Title must be at least 2 characters long"
                                )
                              );
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    />
                    <Row gutter={16}>
                      <CustomDatePicker
                        col={{ lg: 12, xs: 24 }}
                        name="deliveryDate"
                        label="Delivery Date"
                        setDate={setDate}
                        format="MM-DD-YYYY"
                        placeholder="MM-DD-YYYY"
                      />
                      <RenderTimePicker
                        col={{ lg: 12, xs: 24 }}
                        name="deliveryTime"
                        placeholder="HH:MM"
                        format="h:mm a"
                        label="Delivery Time"
                      />
                    </Row>

                    <RenderTextArea
                      col={{ lg: 24, xs: 24 }}
                      name="body"
                      placeholder="Body"
                      label="Body"
                      rows={5}
                    />
                  </Row>
                </Col>
                <Col lg={12} xs={24} className="mt-md-35">
                  <Row gutter={[0, 36]}>
                    <Col lg={24} xs={24}>
                      <Form.Item
                        label={<> Image{<span>(Optional)</span>} <span className="desc">
                          (File must be less than 1MB.)
                        </span></>}
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name="image"
                      >
                        <Upload
                          {...props}
                          listType="picture-card"
                          fileList={restaurantImage}
                          onPreview={false}
                          onChange={handleChange}
                          className="cover-upload"
                          beforeUpload={() => {
                            return false;
                          }}
                        >
                          {restaurantImage.length > 0
                            ? null
                            : uploadCoverButton}
                        </Upload>

                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col xs={24} className="mt-35">
                  <Row gutter={[0, 36]}>
                    <div className="btn-group d-flex align-items-center justify-content-end w-100">
                      <Button
                        type="default"
                        htmlType="button"
                        onClick={() =>
                          navigate("/communications/active", {
                            state: {
                              record: location?.state?.record,
                            },
                          })
                        }
                      >
                        Cancel
                      </Button>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={btndisabled || loading}
                        loading={loading}
                        className={`${btndisabled === true ? "disable" : ""}`}
                      >
                        {isNext ? "Add" : "Add Communication"}
                      </Button>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        {ModalOpen && (
          <Modal
            open={ModalOpen}
            centered
            width={580}
            className="claim-location-modal"
            onOk={handleModalOk}
            onCancel={handleModalOk}
            footer={null}
          >
            <div className="location-card">
              <div className="card-header">
                <picture>
                  <SendIcon />
                </picture>
                <h2 className="card-header">
                  {isNext
                    ? "Your Deal and Communication have been sent for review"
                    : "Communication Sent for Review"}
                </h2>
              </div>
              <div className="card-body">
                <p className="card-desc">
                  {isNext
                    ? "They will be activated upon approval."
                    : "It will be activated upon approval."}
                </p>
              </div>
              <div className="card-footer">
                <Button type="primary" onClick={handleModalOk}>
                  Ok
                </Button>
              </div>
            </div>
          </Modal>
        )}
      </Wrapper>
    </>
  );
};

export default AddCommunications;
