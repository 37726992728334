import { createGlobalStyle } from "styled-components";
import { toAbsoluteUrl } from "../../utils";
import { device } from './../breakpoints';

export const CommonTable = createGlobalStyle`

    .custom-wrap{
        box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
        -ms-box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
        -moz-box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
        -webkit-box-shadow: 0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
        border-radius: 8px;
        background-color: var(--white--color);
        .table-title{
            font-weight: 500;
            font-size: 14px;
            color: #242424;
            padding:12px 16px;
            border-bottom:1px solid #E6E8F0;
            margin-bottom: 42px;
        }
    }
    .ant-table-wrapper {
        .ant-table-pagination{
            display:none;
        }
        .ant-table-thead{
            tr{
                th{
                    background:var(--theme--color);
                    font-family: 'SF Pro Display';
                    font-weight: 500;
                    font-size: 12px;
                    letter-spacing: 0.5px;
                    text-transform: uppercase;
                    color: var(--white--color);
                    padding: 10px 50px;
                    text-transform: uppercase;
                    /* background: #fafafa; */
                    &:before{
                      display: none;
                    }
                    &.action{
                        font-size: 0;
                    }
                    &.ant-th-venue{
                        background:#F5F7F9;
                        color: #242424;
                        border-radius: 0 !important;
                    }
                }
                td{
                       background: rgb(245, 247, 249);
                }
            }
        }
        .ant-table-tbody{
            tr{
              td{
                    font-family: 'SF Pro Display';
                    font-weight: 500;
                    font-size: 14px;
                    color: #242424;
                    padding:32px 50px;
                    border-bottom:1px solid  #E6E8F0;
                    &.ant-regular{
                        font-weight: 400;
                    }
                }
          }
        }
        .number{
            color: #FE7A18;
        }
        .venue-image{
            figure{
                width: 42px;
                height: 42px;
                margin-right: 8px;
                img{
                    width: 42px;
                    height: 42px;
                    object-fit: cover;
                    border-radius: 100%;
                }
            }
        }
        .location{
            img{
                margin-right: 8px;
            }
        }
        .primary{
            color: #FE7A18;
        }
        .status {
            position: relative;
            padding-left: 14px;
            &::before {
                content: "";
                min-width: 6px;
                max-width: 6px;
                min-height: 6px;
                max-height: 6px;
                border-radius: 50%;
                background: #B3B3B3;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &.active {
                &::before {
                    background-color: #00A651;
                }
            }
        }
        .expired{
            position: relative;
            padding-left: 14px;
            &::before {
                content: "";
                min-width: 6px;
                max-width: 6px;
                min-height: 6px;
                max-height: 6px;
                border-radius: 50%;
                background: red;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &.active {
                &::before {
                    background-color: #00A651;
                }
            }
        }
        .statusVenue {
            position: relative;
            padding-left: 14px;
            &::before {
                content: "";
                min-width: 6px;
                max-width: 6px;
                min-height: 6px;
                max-height: 6px;
                border-radius: 50%;
                background: #F68C3D;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &.active {
                &::before {
                    background-color: #00A651;
                }
            }
        }
        .approved {
            position: relative;
            padding-left: 14px;
            &::before {
                content: "";
                min-width: 6px;
                max-width: 6px;
                min-height: 6px;
                max-height: 6px;
                border-radius: 50%;
                background: #234093;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &.active {
                &::before {
                    background-color: #00A651;
                }
            }
        }
        .ant-spin-dot-item {
            background-color: #f6883d;
        }
    }

    .ant-table-wrapper.ant-venue-table-menu {
        .ant-table{
            padding-bottom: 40px;
            border-spacing: 10px 0;
            .mr-20{
                margin-right: 20px;
            }
            
        }
        .ant-table-thead{
            tr{
                th{
                    padding: 14px 0;
                    border-bottom:none;
                    background:#F5F7F9;
                    color: rgb(36, 36, 36);
                    border-radius: 0 !important;
                    &.ant-table-selection-column{
                        width: 42px;
                    }
                }
            }
        }
        .ant-table-tbody{
            tr{
                td{
                    padding:25px 0 0; 
                    border-bottom:none;
                    background: transparent !important;
                }
            }
        }
    }
    .custom-pagination{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 13px 22px;
        .ant-pagination{
            display: flex;
            align-items: center;
            li{
                font-family: 'SF Pro Display';
                font-weight: 400;
                font-size: 12px;
                color: #242424;
                min-width:auto;
                margin:0;
                &:nth-child(1){
                    order:2;
                    margin: 0 15px;
                }
                &:nth-child(2){
                    order:1;
                }
                &:nth-child(3){
                    order:3;
                }
                &.ant-pagination-simple-pager{
                    margin: 0;
                    display: flex;
                    align-items: center;
                    input{
                        width: 28px;
                        padding: 0;
                        border: none;
                        margin: 0;
                        font-weight: 400;
                        font-size: 12px;
                        color: #242424;
                        &:focus{
                            box-shadow:none;
                        }
                    }
                    .ant-pagination-slash{
                        margin-inline-end:4px;
                        margin-inline-start: 0;
                        font-size:0;
                        &:before{
                            content: "of";
                            font-size:12px;
                        }
                    }
                }
            }
            &.pagination-none{
                margin-right:10px;
                li{
                    display: none;
                    &.ant-pagination-options{
                        display: flex;
                        align-items: center;
                        margin: 0;
                        &:before{
                            font-family: 'SF Pro Display';
                            font-weight: 400;
                            font-size: 12px;
                            color: #6B7280;
                            content: "Rows per page:";
                            margin-right:8px;
                            @media only screen and (${device.sm}){
                                margin-right:5px;
                            }
                        }
                        .ant-select{
                            .ant-select-selector{
                                height: auto;
                                padding: 0;
                                border: none;
                                box-shadow:none;
                                .ant-select-selection-search{
                                    display: none;
                                }
                                .ant-select-selection-item{
                                    font-family: 'SF Pro Display';
                                    font-weight: 400;
                                    font-size: 12px;
                                    letter-spacing: 0.3px;
                                    color: #242424;
                                    &:after{
                                        position: absolute;
                                        content: "";
                                        visibility: visible;
                                        background-image: url(${toAbsoluteUrl(`/images/pagination-arrow.svg`)});
                                        right: 0;
                                        top: 0;
                                        bottom: 0;
                                        margin: auto 0;
                                        width: 11px;
                                        height: 5px;
                                    }
                                }
                            }
                            .ant-select-arrow{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    } 
    .ant-table-wrapper .ant-table-tbody tr.ant-table-expanded-row > td.ant-table-cell {
        padding: 0;
        td.ant-table-cell {
            padding: 24px 50px;
            background: #F5F7F9 !important;
        }
        th.ant-table-cell.ant-table-row-expand-icon-cell{
            background: #F5F7F9 !important;
        }
        .ant-table {
            margin: 0;
        }
    }
`;