import { createGlobalStyle } from "styled-components";
import { device } from "./breakpoints";
const Common = createGlobalStyle`


//Fonts Size

// h1 {
    
// }
// h2 {
// }

// h3 {
   
// }
// h4 {
    
// h5 {
    
// }
// p {
    
// }

/*--- Margin and Padding ---*/

//padding
.p-0{
    padding: 0;
}
.pr-0{
    padding-right: 0;
}
.pl-0{
    padding-left: 0;
}
.pt-0{
    padding-top: 0;
}
.pb-0{
    padding-bottom: 0;
}

// margin
.m-0{
    padding: 0;
}
.mr-0{
    margin-right: 0;
}
.ml-0{
    margin-left: 0;
}
.mt-0{
    margin-top: 0;
}
.mb-0{
    margin-bottom: 0;
}
.mt-10{
    margin-top : 10px;
}
.mb-30{
    margin-bottom: 30px;
}
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-success {
    color: --success--color;
}

.text-danger {
    color: --danger--color;
    margin-top: 5px;
    line-height: 1;
}

.text-warning {
    color: --warning--color;
}

.text-info {
    color: --info--color;
}


// Width css
.w-100 {
    width: 100%;
}

// Height SCSS
.min-h100 {
    min-height: 100vh;
}

.h-100 {
    height: 100%;
}

.container{
    width: 1290px;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}

.container-full {
    width: 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.shadow-form{
    box-shadow:  0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
    -ms-box-shadow:  0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
    -moz-box-shadow:  0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
    -webkit-box-shadow:  0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
    background: var(--white--color);
    border-radius: 8px;
    padding: 60px 140px;
}
.page-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:34px 0px;
    h2{
        font-weight: 700;
        font-size: 32px;
        color: #242424;
        margin-bottom:0;
    }
    &.page-sub-header{
        margin: 20px 0 30px;
    }
    .icon-box{
        margin-bottom: 20px;
    }
}

    .ant-dropdown-menu{
        text-align:left;
    }

    .ant-select-single{
        height:auto !important;
    }
.heading-title{
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    display: flex;
    align-items: center;
    i{
        margin-right:7px;
        display:inline-block;
    }
}
.ant-table-wrapper.ant-expand .ant-table-thead tr th:first-child{ 
    background: rgba(245, 247, 249,1) !important;
}
.ant-table-wrapper.ant-expand .ant-table-thead tr td:first-child{ 
    background: rgba(245, 247, 249,1) !important;
}


@media only screen and (max-width:1599px){
    .shadow-form{
        padding: 60px;
    }
}
@media only screen and (${device.lg}){
    .page-header{
        margin: 0 0 30px;
        h2{
            font-size: 26px;
        }
    }
    .shadow-form{
        padding: 30px 20px;
    }
}
@media only screen and (${device.sm}){
    .page-header{
        h2{
            font-size: 22px;
        }
    }
}


`;

export default Common;
