import { createGlobalStyle } from "styled-components";

const Theme = createGlobalStyle`

    .ant-btn.ant-btn-primary{
        background-color: var(--theme--color) !important;
        border-radius: 8px;
        font-family: "SF Pro Display";
        font-weight: 500;
        font-size: 15px;
        color:var(--white--color);
        padding: 16px 40px;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        &.ant-small{
            padding: 12px 24px;
            font-family: 'SF Pro Display';
            font-weight: 500;
            font-size: 15px;
            color: var(--white--color);
            svg{
                margin:0 12px 0 0;
            }
        }
        svg{
            margin-left: 12px;
        }
        &:hover,&:focus{
            background-color: var(--theme--color); 
        }
        &.disable{
            background-color: #F9B786;
        }
    }
    .ant-btn.ant-btn-default{
        padding: 0;
        border: none;
        box-shadow: none;
        font-family: 'SF Pro Display';
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.14px;
        color: var(--theme--color);
        background: none;
        &:hover,&:focus{
            color: var(--theme--color); 
        }
        &:disabled{
            background:none ;
            color: rgba(55, 65, 81, 0.48);
        }
    }


    .btn-boxshadow{
        box-shadow:  0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
        -ms-box-shadow:  0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
        -moz-box-shadow:  0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
        -webkit-box-shadow:  0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1);
    }

    .ant-switch{
        &.ant-switch-checked{
            background:var(--theme--color);

            &:hover:not(.ant-switch-disabled){
                background-color: var(--theme--color);
            }
        }
    }


    /* --- layout-popover ---- */
    .ant-btn.ant-more-btn{
        background-color: transparent;
        border: none;
        min-width: auto;
        padding: 0;
        width: auto;
        height: auto;
        line-height: initial;
        padding: 5px;
    }
    .ant-popover{
        .ant-popover-arrow{
            display:none;
        }
        .ant-popover-content{
            border-radius: 8px;
            .ant-popover-inner{
                padding:8px 0;
                width: 200px;
                background: var(--white--color);
                border: 1px solid #E5E7EB;
                box-shadow:0px 4px 6px -2px rgba(100, 116, 139, 0.05), 0px 10px 15px -3px rgba(100, 116, 139, 0.12);
                -ms-box-shadow:0px 4px 6px -2px rgba(100, 116, 139, 0.05), 0px 10px 15px -3px rgba(100, 116, 139, 0.12);
                -moz-box-shadow:0px 4px 6px -2px rgba(100, 116, 139, 0.05), 0px 10px 15px -3px rgba(100, 116, 139, 0.12);
                -webkit-box-shadow:0px 4px 6px -2px rgba(100, 116, 139, 0.05), 0px 10px 15px -3px rgba(100, 116, 139, 0.12);
                .ant-menu {
                    border:none;
                    .ant-menu-item{
                        height: auto;
                        margin: 0;
                        padding:0;
                        width: 100%;
                        text-align: center;
                        background-color: var(--white--color);
                        border-radius: 0;
                        &:hover,&.ant-menu-item-active,&.ant-menu-item-selected{
                            background-color: rgba(55, 65, 81, 0.08)
                        }
                        a,.ant-menu-title-content{
                            font-family: 'SF Pro Display';
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 41px;
                            color: #1F2D4A;
                            display:block;
                            padding: 2px 10px;
                            &.delete{
                                color: #D14343
                            }
                       }
                       .delete{
                            font-family: 'SF Pro Display';
                            font-weight: 400;
                            font-size: 16px;
                            color: #D14343;
                       }
                       .ant-menu-title-content{
                           span{
                                display: block;
                                width: 100%;
                                height: 100%;
                           }
                       }
                    }
                }
            }
        }
    }

`;

export default Theme